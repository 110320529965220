import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleChevronRight,
    faCircleChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Items from "./Items";
import "../style/services.scss";

function previousService() {
    const caroussel = document.querySelector(".caroussel");
    caroussel.scrollLeft += 300;
}

function nextService() {
    const caroussel = document.querySelector(".caroussel");
    caroussel.scrollLeft += 300;
}

export default function Services() {
    return (
        <section class="services">
            <h2>Solutions proposées</h2>
            <div class="caroussel">
                <FontAwesomeIcon
                    icon={faCircleChevronLeft}
                    onClick={previousService}
                />
                <Items title="Landing Page" price="440" />
                <Items title="Vitrine (6 pages)" price="1 040" />
                <Items title="E-commerce" price="2 140" />
                <Items title="Sur mesure" price="3 880" />
                <Items
                    title="Accompagnement à la prise en main des outils"
                    price="800"
                />
                <FontAwesomeIcon
                    icon={faCircleChevronRight}
                    onClick={nextService}
                />
            </div>
        </section>
    );
}
