import "../style/footer.scss";

export default function Footer() {
    return (
        <footer>
            <p>Auto-entrepreneur LebiWeb.RE</p>
            <p>
                Contactez nous pour plus de renseignements{" "}
                <a href="mailto:contact@lebiweb.re">contact@lebiweb.re</a>
            </p>
            <p>© 2021</p>
        </footer>
    );
}
