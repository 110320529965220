import "../style/items.scss";

export default function Items({ title = "item", price = "0" }) {
    return (
        <div class="carousel-item">
            <h3>{title}</h3>
            <p>A partir de {price} €</p>
        </div>
    );
}
