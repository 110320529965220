import profile from "../assets/pexels-fotios-photos-16129728.jpg";
import flag from "../assets/ReunionRadiantVolcanoFlag.png";

export default function Presentation() {
    return (
        <section class="presentation">
            <img src={profile} alt="Internet" />
            <div>
                <h2>Création de sites web par un auto-entrepreneur local</h2>
                <img src={flag} alt="Drapeau Réunionais" />
                <p>
                    Des solutions sur mesure pour votre présence en ligne. Un
                    contact pour vous accompagner dans votre projet
                </p>
                <p>
                    Auto-entrepreneur sur l'île de La Réunion présent pour
                    accompagner les entreprises locales dans une croissance
                    digitale.
                </p>
            </div>
        </section>
    );
}
