import Header from "./Header";
import Footer from "./Footer";
import Presentation from "./Presentation";
import Services from "./Services";
import KapNumerik from "./KapNumerik";
import Entrepreuneur from "./Entrepreuneur";

export default function App() {
    return (
        <>
            <Header />
            <Presentation />
            <Services />
            <Entrepreuneur />
            <KapNumerik />
            <Footer />
        </>
    );
}
