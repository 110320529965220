import logoRegion from "../assets/logolareunion.png";

export default function KapNumerik() {
    return (
        <section class="kapnumerik">
            <img src={logoRegion} alt="Logo de la Région Réunion" />
            <div>
                <h3>Aide de la Région Réunion</h3>
                <p>
                    La Région Réunion a mis en place une aide nommée{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://regionreunion.com/aides-services/article/le-kap-numerik-version-2023-programme-europeen-feder-2021-2027"
                        alt="Aide de la Région"
                    >
                        Kap Numérik
                    </a>
                    . Cette aide permet un remboursement d'une partie des coûts
                    du projet pour les petites structures. Si votre entreprise
                    ou association a moins de 20 salariés, vous pouvez
                    bénéficier de cette aide. Jusqu'à 9 salariés, 80% du projet
                    peuvent être remboursés. Entre 10 et 19 salariés, c'est 50%
                    du projet qui peut être remboursé.
                </p>
                <p>
                    Un Accompagnement à la création du dossier et au dépôt de la
                    demande sera mis en place. Cette aide peut être demandée une
                    fois pas an.
                </p>
                <p>
                    Pour en savoir plus, rendez-vous sur le{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://regionreunion.com/aides-services/article/le-kap-numerik-version-2023-programme-europeen-feder-2021-2027"
                        alt="Aide de la Région"
                    >
                        Site de la Région Réunion
                    </a>
                </p>
            </div>
        </section>
    );
}
