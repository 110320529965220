import coworking from "../assets/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg";

export default function Entrepreuneur() {
    return (
        <section class="about">
            <div>
                <h2>À Propos</h2>
                <p>
                    Passionné par le web, je mets mon expertise à votre service
                    pour créer des sites performants et esthétiques.
                </p>
                <p>
                    Formé à la création d'application web ainsi qu'au design
                    d'interface esthétique, je me propose comme soutient dans
                    votre projet de digitalisation.
                </p>
            </div>
            <img src={coworking} alt="Coworking" />
        </section>
    );
}
