import "../style/header.scss";
import logo from "../assets/Logo_LebiWeb_V1.1.png";

export default function Header() {
    return (
        <header>
            <img src={logo} alt="Logo du site" />
            <div>
                <h1>Auto-entrepreneur LebiWeb.RE</h1>
                <p>Votre univers, notre digitalisation</p>
            </div>
        </header>
    );
}
